import React, { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import Client from 'shopify-buy';
import { useStaticQuery, graphql } from 'gatsby';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;

const client = Client.buildClient({
  domain: 'seadragons.myshopify.com',
  storefrontAccessToken: 'b988b29980d9d9b8794521ab32177cd3',
});

const defaultValues = {
  isCartOpen: false,
  toggleCartOpen: () => {},
  cart: [],
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== 'undefined';

export const StoreProvider = ({ children }) => {
  //
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(QUERY);

  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [isCartOpen, toggleCartOpen] = useState(false);

  const getNewId = async () => {
    try {
      const cookie = isBrowser && JSON.parse(localStorage.getItem(siteUrl));
      const newCheckout = await client.checkout.create();
      localStorage.setItem(siteUrl, JSON.stringify({ ...cookie, checkout_id: newCheckout.id }));
      return newCheckout;
    } catch (error) {
      console.error(error);
    }
  };

  const initializeCheckout = async () => {
    try {
      const cookie = isBrowser && JSON.parse(localStorage.getItem(siteUrl));

      const currentCheckoutId = cookie && cookie.checkout_id;

      let newCheckout = null;

      if (currentCheckoutId) {
        newCheckout = await client.checkout.fetch(currentCheckoutId);
        if (newCheckout.completedAt) {
          newCheckout = await getNewId();
        }
      } else {
        newCheckout = await getNewId();
      }
      setCheckout(newCheckout);
    } catch (error) {
      console.error(error);
    }
  };

  const addProductToCart = async variantId => {
    try {
      const lineItems = [
        {
          variantId,
          quantity: 1,
        },
      ];
      const newCheckout = await client.checkout.addLineItems(checkout.id, lineItems);
      setCheckout(newCheckout);
    } catch (error) {
      console.error(error);
    }
  };

  const updateProductQuantityInCart = async (id, quantity) => {
    try {
      const lineItemsToUpdate = [
        {
          id,
          quantity,
        },
      ];
      const newCheckout = await client.checkout.updateLineItems(checkout.id, lineItemsToUpdate);
      setCheckout(newCheckout);
    } catch (error) {
      console.error(error);
    }
  };

  const removeProductFromCart = async lineItemIdsToRemove => {
    try {
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [lineItemIdsToRemove]);
      setCheckout(newCheckout);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    initializeCheckout();
  }, []);

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addProductToCart,
        updateProductQuantityInCart,
        removeProductFromCart,
        checkout,
        isCartOpen,
        toggleCartOpen,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
