import React from 'react';
import styled from 'styled-components';
import Spinner from '../svg/Spinner';

const Wrapper = styled.div.attrs({})`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Loading = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Loader = () => {
  //
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  );
};
