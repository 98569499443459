import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { Container } from '../primitives';
import Facebook from '../svg/Facebook';
import Instagram from '../svg/Instagram';
import YouTube from '../svg/YouTube';

const QUERY = graphql`
  query {
    site {
      ...BusinessInfo
    }
  }
`;

const Wrapper = styled.footer.attrs({ className: 'text-white text-sm' })`
  a {
    color: white;
    font-size: 0.875rem;
  }
  svg {
    height: 24px;
    fill: #ffffff;
  }
`;

const LinkWrapper = styled.div.attrs({})`
  a {
    display: inline-block;
    font-size: 1rem;
    margin: 0 0 0.5rem 0;
  }
`;

const TopFooter = styled.div.attrs({
  className: 'py-20',
})`
  background: #0e4673;
`;

const BottomFooter = styled.div.attrs({ className: 'bg-gray-900 py-4' })``;

const Grid = styled.div`
  padding: 0 2rem;
  #footer-grid-1 {
  }
  display: grid;
  grid-row-gap: 2rem;
  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
    #footer-grid-2 {
      display: grid;
      justify-items: end;
      text-align: right;
    }
  }
`;

const Hours = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 140px;
`;

const SocialMedia = styled.div.attrs({ className: 'flex' })`
  a {
    margin: 0 1rem 0 0;
    @media (min-width: 680px) {
      margin: 0 0 0 1rem;
    }
  }
  margin: 0 0 1rem 0;
`;

const Footer = () => {
  //
  const {
    site: { siteMetadata },
  } = useStaticQuery(QUERY);

  const {
    contact,
    business: { hours },
    socialMedia,
    siteName,
  } = siteMetadata;

  const year = new Date().getFullYear();

  return (
    <Wrapper>
      <TopFooter>
        <Container>
          <Grid>
            <div id="footer-grid-1">
              <a href={`tel:${contact.telephone}`}>{contact.telephone}</a>
              <br />
              <a href={contact.googleMap} target="_blank" rel="noopener noreferrer">
                <br />
                {contact.address.streetAddress},
                <br />
                {contact.address.addressLocality},
                <br />
                {contact.address.postalCode},
                <br />
                {contact.address.addressRegion}, {contact.address.addressCountryLong}.
                <br />
              </a>
              <br />
              <strong>Hours</strong>
              {hours.map(hour => (
                <Hours key={hour.day}>
                  <span>{hour.day}</span>
                  <span>{hour.duration}</span>
                </Hours>
              ))}
            </div>
            <div id="footer-grid-2">
              <SocialMedia>
                <a href={socialMedia.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook">
                  <Facebook />
                </a>
                <a href={socialMedia.instagram} target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <Instagram />
                </a>
                <a href={socialMedia.youtube} target="_blank" rel="noopener noreferrer" aria-label="Youtube">
                  <YouTube />
                </a>
              </SocialMedia>
              <LinkWrapper>
                <div>{/* <Link to="/career">Jobs at SDAA</Link> */}</div>
                <br />
                <ul>
                  <li>
                    <Link to="/privacy">Privacy Notice</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms of Use</Link>
                  </li>
                  <li>
                    <Link to="/cookies">Cookie Policy</Link>
                  </li>
                  <li>
                    <Link to="/sitemap">Sitemap</Link>
                  </li>
                </ul>
              </LinkWrapper>
            </div>
          </Grid>
        </Container>
      </TopFooter>
      <BottomFooter>
        <Container>
          Copyright &copy; {year} | {siteName}
        </Container>
      </BottomFooter>
    </Wrapper>
  );
};

export default Footer;
