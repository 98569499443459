/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const Block = styled.div`
  position: relative;
  padding: 4rem 0;
  @media (min-width: 680px) {
    padding: 6rem 0;
  }
  @media (min-width: 1000px) {
    padding: 8rem 0;
  }
`;
