/* eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence } from 'framer-motion';
import Layout from '../components/layout/Layout';

const wrapPageElement = ({ element, props: { uri } }) => {
  return (
    <Layout path={`/${uri.split('/')[1]}`}>
      <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
    </Layout>
  );
};

export { wrapPageElement };

wrapPageElement.propTypes = {
  element: PropTypes.node.isRequired,
  props: PropTypes.shape({
    uri: PropTypes.string.isRequired,
  }).isRequired,
};
