/* eslint-disable react/jsx-props-no-spreading */

import * as React from 'react';

function SvgInstagram(props) {
  return (
    <svg viewBox="0 0 40 40" {...props}>
      <g data-name="Layer 2">
        <path
          d="M20 0c5.43 0 6.11 0 8.25.12a14.87 14.87 0 014.85.93 9.63 9.63 0 013.54 2.31A9.63 9.63 0 0139 6.9a14.87 14.87 0 01.93 4.85C40 13.89 40 14.57 40 20s0 6.11-.12 8.25A14.87 14.87 0 0139 33.1a10.27 10.27 0 01-5.9 5.9 14.87 14.87 0 01-4.85.93C26.11 40 25.43 40 20 40s-6.11 0-8.25-.12A14.87 14.87 0 016.9 39a9.63 9.63 0 01-3.54-2.31 9.63 9.63 0 01-2.31-3.59 14.87 14.87 0 01-.93-4.85C0 26.11 0 25.43 0 20s0-6.11.12-8.25a14.87 14.87 0 01.93-4.85 9.63 9.63 0 012.31-3.54A9.63 9.63 0 016.9 1.05a14.87 14.87 0 014.85-.93C13.89 0 14.57 0 20 0zm0 3.6c-5.34 0-6 0-8.08.12a11 11 0 00-3.72.69A6.56 6.56 0 004.41 8.2a11 11 0 00-.69 3.72C3.62 14 3.6 14.66 3.6 20s0 6 .12 8.08a11 11 0 00.69 3.72 6.56 6.56 0 003.79 3.79 11 11 0 003.72.69c2.11.1 2.74.12 8.08.12s6 0 8.08-.12a11 11 0 003.72-.69 6.56 6.56 0 003.79-3.79 11 11 0 00.69-3.72c.1-2.11.12-2.74.12-8.08s0-6-.12-8.08a11 11 0 00-.69-3.72 6.56 6.56 0 00-3.79-3.79 11 11 0 00-3.72-.69C26 3.62 25.34 3.6 20 3.6zm0 6.13A10.27 10.27 0 119.73 20 10.27 10.27 0 0120 9.73zm0 16.94A6.67 6.67 0 1013.33 20 6.67 6.67 0 0020 26.67zM33.08 9.32a2.4 2.4 0 11-2.4-2.4 2.4 2.4 0 012.4 2.4z"
          fillRule="evenodd"
          data-name="Layer 1"
        />
      </g>
    </svg>
  );
}

export default SvgInstagram;
