import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useStaticQuery, graphql, navigate } from 'gatsby';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { useScrollPosition } from '../../utils';
import Logomark from '../svg/Logomark';
import Logotype from '../svg/Logotype';
import Hamburger from '../svg/Hamburger';
import { Button } from '../primitives';

const QUERY = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        pages {
          label
          link
          alpha
        }
        cookieLifeInDays
      }
    }
  }
`;

const privacyMessagePopup = {
  hidden: {
    opacity: 0,
    y: '100%',
    transition: {
      delay: 0,
    },
  },
  visible: {
    opacity: 1,
    y: '0%',
    transition: {
      delay: 2,
    },
  },
};

const mobileMenu = {
  hidden: {
    opacity: 1,
    translateX: '100%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
  visible: {
    opacity: 1,
    translateX: '0%',
    transition: {
      ease: 'easeInOut',
      duration: 0.1,
    },
  },
};

// initial={{ translateX: '100%' }}
//   animate={{ translateX: isOpen ? '0%' : '100%' }}
//   exit={{ opacity: 0 }}
//   transition={{ ease: 'easeInOut', duration: 0.1 }}

const Nav = styled.nav.attrs({ className: 'py-4 fixed w-full' })`
  z-index: 997;
  transition: 1s color fill;
  box-shadow: ${props => props.navStyles.shadow};
  color: ${props => props.navStyles.a};
  a {
  }
  svg {
    fill: ${props => props.navStyles.a};
  }
`;

const Container = styled.div`
  max-width: 1920px;
  padding: 0 1rem;
  margin: 0 auto;
  @media (min-width: 680px) {
    padding: 0 3rem;
  }
`;

const NavContainer = styled(Container).attrs({ className: 'flex justify-between items-center' })``;

const NavLeftContainer = styled.div.attrs({})``;

const NavRightContainer = styled.div.attrs({})``;

const BackgroundColor = styled.div`
  transition: 1s opacity;
  position: absolute;
  background: #ffffff;
  opacity: ${props => props.navStyles.opacity};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const LogoContainer = styled.button.attrs({
  className: 'flex items-end focus:outline-none relative',
})`
  z-index: 999;
  svg {
    transition: 500ms;
  }
  @media (max-width: 680px) {
    svg {
      transition: 500ms;
      height: 28px;
    }
  }
`;

const LogoTypeWrapper = styled.div`
  padding: 0 0 0 1rem;
  svg {
    height: 14px;
  }
`;

const HideLogo = styled.span`
  @media (max-width: 1100px) {
    display: none;
  }
`;

const LinkContainer = styled.div.attrs({})`
  display: none;
  align-self: flex-start;
  a {
    margin: 0 1.5rem 0 0;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
  }
  @media (min-width: ${props => props.breakpoint}) {
    display: ${props => (props.mobileMenuDrawer ? 'none' : 'block')};
  }
`;

const MobileMenuButton = styled.button.attrs({
  className: 'focus:outline-none relative',
})`
  z-index: 999;
  @media (min-width: ${props => props.breakpoint}) {
    display: ${props => (props.mobileMenuDrawer ? 'block' : 'none')};
  }
`;

const MobileMenu = styled(motion.div)`
  position: fixed;
  background: #0e4673;
  z-index: 998;
  width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: ${props => (props.open ? 'auto' : 'none')};
  padding: 6rem 1rem 0;
`;

const MobileLinkWrapper = styled.div.attrs({ className: 'text-white' })`
  display: grid;
  a {
    transition: 0.3s;
    text-align: right;
    font-size: 1.5rem;
    display: inline-block;
    margin: 1rem 2rem 0.5rem 0;
  }
`;

const PrivacyMessagePopup = styled(motion.div)`
  background: #fefcbf;
  color: #000000;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  opacity: 0;
`;

const PrivacyMessagePopupBody = styled.div`
  padding: 4rem 2rem;
  max-width: 600px;
  margin: 0 auto;
`;

const Padding = styled.div`
  height: 60px;
`;

const Navbar = ({ breakpoint, path }) => {
  //

  const {
    site: {
      siteMetadata: { siteUrl, pages, cookieLifeInDays },
    },
  } = useStaticQuery(QUERY);

  const [showPrivacyMessagePopup, togglePrivacyMessagePopup] = useState(false);
  const [isOpen, toggleMenu] = useState(false);
  const [navbarState, setNavbarState] = useState({
    transparency: true,
    position: 'top',
  });

  const [navStyles, setNavStyles] = useState({ a: '#ffffff' });

  useEffect(() => {
    const cookie = JSON.parse(localStorage.getItem(siteUrl));
    const now = Date.now();
    if (!cookie) {
      togglePrivacyMessagePopup(true);
    }
    if (cookie) {
      const { cookieExpiry } = cookie;
      if (now > cookieExpiry) {
        togglePrivacyMessagePopup(true);
      }
    }
  }, []);

  useEffect(() => {
    const isAlpha = pages.filter(obj => {
      return obj.link === path;
    });

    setNavbarState({
      ...navbarState,
      transparency: isAlpha.length > 0 && isAlpha[0].alpha,
    });
  }, [path]);

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //
      if (currPos.y > -60) {
        const newPosition = 'top';
        if (newPosition !== navbarState.position) {
          setNavbarState({ ...navbarState, position: newPosition });
        }
      }

      if (currPos.y < -60) {
        const newPosition = 'bottom';
        if (newPosition !== navbarState.position) {
          setNavbarState({ ...navbarState, position: newPosition });
        }
      }
    },
    [navbarState]
  );

  useEffect(() => {
    const { transparency, position } = navbarState;

    switch (isOpen) {
      case false:
        if (transparency && position === 'top') {
          setNavStyles({ ...navStyles, a: '#ffffff', opacity: 0, shadow: 'none' });
        }
        if (!transparency && position === 'top') {
          setNavStyles({
            ...navStyles,
            a: '#000000',
            opacity: 1,
            shadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          });
        }
        if (position === 'bottom') {
          setNavStyles({
            ...navStyles,
            a: '#000000',
            opacity: 1,
            shadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
          });
        }
        break;
      case true:
        setNavStyles({
          ...navStyles,
          a: '#ffffff',
        });
        break;
      default:
    }
  }, [navbarState, isOpen]);

  const handleMobileMenu = () => {
    toggleMenu(!isOpen);
  };

  const closeMobileMenu = () => {
    toggleMenu(false);
  };

  const handleLogoLink = () => {
    toggleMenu(false);
    navigate('/');
  };

  const setPrivacyCookie = () => {
    const cookie = JSON.parse(localStorage.getItem(siteUrl));
    const cookieExpiry = Date.now() + 1000 * 60 * 60 * 24 * cookieLifeInDays;
    localStorage.setItem(siteUrl, JSON.stringify({ ...cookie, privacyAgree: 'true', cookieExpiry }));
    togglePrivacyMessagePopup(false);
  };

  return (
    <>
      <Nav navStyles={navStyles}>
        <NavContainer>
          <NavLeftContainer>
            <LogoContainer onClick={handleLogoLink} aria-label="Logo" type="button">
              <Logomark />
              <HideLogo breakpoint={breakpoint}>
                <LogoTypeWrapper>
                  <Logotype />
                </LogoTypeWrapper>
              </HideLogo>
            </LogoContainer>
          </NavLeftContainer>
          <NavRightContainer>
            <LinkContainer breakpoint={breakpoint} mobileMenuDrawer={isOpen}>
              {pages.map(page => (
                <Link key={page.link} to={page.link} activeClassName="navbarActiveStyles">
                  {page.label}
                </Link>
              ))}
            </LinkContainer>
            <MobileMenuButton
              onClick={handleMobileMenu}
              mobileMenuDrawer={isOpen}
              breakpoint={breakpoint}
              aria-label="mobile menu button"
              type="button"
            >
              <Hamburger />
            </MobileMenuButton>
          </NavRightContainer>
        </NavContainer>
        <AnimatePresence>
          {isOpen && (
            <MobileMenu open={isOpen} initial="hidden" animate="visible" exit="hidden" variants={mobileMenu}>
              <MobileLinkWrapper>
                {pages.map(page => (
                  <Link
                    key={page.link}
                    to={page.link}
                    onClick={closeMobileMenu}
                    activeClassName="mobileMenuActiveStyles"
                  >
                    {page.label}
                  </Link>
                ))}
                {/* <Link to="/sitemap" onClick={closeMobileMenu} activeClassName="mobileMenuActiveStyles">
                  Sitemap
                </Link> */}
              </MobileLinkWrapper>
            </MobileMenu>
          )}
        </AnimatePresence>
        <BackgroundColor navStyles={navStyles} />
      </Nav>
      {!navbarState.transparency && <Padding />}
      <AnimatePresence>
        {showPrivacyMessagePopup && (
          <PrivacyMessagePopup initial="hidden" animate="visible" exit="hidden" variants={privacyMessagePopup}>
            <PrivacyMessagePopupBody>
              <div className="flex justify-end pb-4">
                <Button type="button" onClick={setPrivacyCookie}>
                  Agree
                </Button>
              </div>
              <div>
                Cookies help us provide, protect and improve our website. By using our website, you agree to our use of
                cookies.
              </div>
            </PrivacyMessagePopupBody>
          </PrivacyMessagePopup>
        )}
      </AnimatePresence>
    </>
  );
};

export default Navbar;

Navbar.propTypes = {
  breakpoint: PropTypes.string,
  path: PropTypes.string.isRequired,
};

Navbar.defaultProps = {
  breakpoint: '840px',
};
