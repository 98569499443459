import * as React from 'react';

function SvgClose(props) {
  return (
    <svg viewBox="0 0 100 100" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <path fill="transparent" d="M0 0h100v100H0z" />
          <path d="M32.65 72.52l17.24-17.35 17.24 17.35 4.74-4.74-17.35-17.24L71.87 33.3l-4.74-4.63-17.24 17.24-17.24-17.24-4.63 4.63 17.24 17.24-17.24 17.24 4.63 4.74z" />
        </g>
      </g>
    </svg>
  );
}

export default SvgClose;
