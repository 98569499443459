import React from "react";

function SvgHamburger(props) {
  return (
    <svg width="32px" viewBox="0 0 100 100" {...props}>
      <g>
        <path fill="#000000" fillOpacity="0" d="M0 0h100v100H0z" />
        <path d="M85.4 22.19v5H14.6v-5zM14.6 52.53h70.8v-5.06H14.6zm0 25.28h70.8v-5H14.6z" />
      </g>
    </svg>
  );
}

export default SvgHamburger;