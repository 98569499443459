import styled from 'styled-components';

const commonStyles = {
  className: 'py-4 px-8 text-white text-base focus:outline-none duration-300',
};

// Button
// Todo add disabled state
export const Button = styled.button.attrs(commonStyles)`
  padding: 0.5rem 1.5rem;
  color: #000000;
  border: 1px #a0aec0 solid;
  :hover {
    color: #000000;
    box-shadow: rgba(0, 124, 137, 0.3) 0 0 0 0.0625rem inset;
  }
  :disabled {
    background: gray;
    cursor: not-allowed;
    transform: none;
  }
`;

// AnchorAsButton
// Todo add disabled state
export const AnchorAsButton = styled.a.attrs(commonStyles)`
  padding: 0.5rem 1.5rem;
  color: #000000;
  border: 1px #a0aec0 solid;
  :hover {
    color: #000000;
    box-shadow: rgba(0, 124, 137, 0.3) 0 0 0 0.0625rem inset;
  }
  :disabled {
    background: gray;
    cursor: not-allowed;
    transform: none;
  }
  cursor: pointer;
  display: inline-block;
`;

// Anchor
// Todo add disabled state
export const Anchor = styled.a`
  :focus {
    outline: none;
  }
  cursor: pointer;
  color: #1a202c;
  transition: all 0.2s ease-in-out;
  border-bottom: 2px #a0aec0 solid;
  }
  :hover {
    color: #000000;
    border-bottom: 2px #0E4673 solid;
  }
`;

// Hero Button
// Todo add disabled state
export const HeroButton = styled.button.attrs({
  className: 'text-black py-3 px-6 focus:outline-none duration-300',
})`
  background: #ffd700;
  color: #000000;
  font-size: 1.25rem;
  font-weight: 500;
  :hover {
    background: #0e4673;
    color: #ffffff;
  }
`;
