/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  @media (max-width: 1132px) {
    padding: 0 1rem;
  }
`;
