/* eslint-disable import/prefer-default-export */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';

const wrapper = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0, y: -400 },
};

const dialogBox = {
  initial: {
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
  animate: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  exit: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, 0)',
  },
};

const Wrapper = styled(motion.div)`
  position: fixed;
  z-index: 9999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`;

const Box = styled(motion.div)`
  position: fixed;
  width: 100%;
`;

export const Modal = ({ open, children }) => {
  //
  return (
    <AnimatePresence>
      {open && (
        <Wrapper key="modal" initial="inital" animate="animate" exit="exit" variants={wrapper}>
          <Box key="box" initial="initial" animate="animate" exit="exit" variants={dialogBox}>
            {children}
          </Box>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
