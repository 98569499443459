/* eslint-disable import/prefer-default-export */

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from './Modal';
import Icon from '../svg/Close';

const Body = styled.div`
  background: #f6f6f4;
  button {
    :focus {
      outline: 0;
    }
  }
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: #000000;
  z-index: 1000;
  border: 0.5rem #ffffff solid;
  outline: 1px #a0aec0 solid;
  svg {
    width: 24px;
    height: 24px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1rem;
`;

const Children = styled.div`
  padding: 1rem 2rem 1rem 1rem;
`;

export const Dialog = ({ open, close, children }) => {
  //
  return (
    <Modal open={open}>
      <Body>
        <ButtonWrapper>
          <button type="button" onClick={()=>close(false)}>
            <Icon />
          </button>
        </ButtonWrapper>
        <Children>{children}</Children>
      </Body>
    </Modal>
  );
};

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
