/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  input {
    border: ${props => `1px ${props.errorText ? props.errorBorderColor : props.defaultBorderColor} solid`};
    outline: none;
    width: 100%;
    padding: 0.5rem 1rem;
    margin-bottom: 2rem;
    background: #f7fafc;
    ::placeholder {
      color: grey;
    }
  }

  label {
    display: flex;
    margin-bottom: 0.5rem;
    color: ${props => (props.errorText ? props.errorBorderColor : '#000000')};
    font-size: 0.875rem;
  }
  em {
    font-size: 0.8em;
    color: tomato;
    font-weight: 600;
  }
`;

const AsteriskStyle = styled.span`
  margin-left: 0.25rem;
`;

export const Input = ({
  labelText,
  labelClasses,
  inputName,
  inputClasses,
  value,
  type,
  errorText,
  placeholder,
  asterisk,
  errorBorderColor,
  defaultBorderColor,
  min,
  autocomplete,
  onChange,
  onFocus,
  onBlur,
}) => {
  //

  return (
    <Wrapper errorText={errorText} errorBorderColor={errorBorderColor} defaultBorderColor={defaultBorderColor}>
      <label htmlFor={inputName} className={labelClasses}>
        {errorText || labelText}
        <AsteriskStyle>
          {asterisk && !value && !errorText ? (
            <>
              <em>[Required]</em>
            </>
          ) : (
            ''
          )}
        </AsteriskStyle>
      </label>
      <input
        name={inputName}
        type={type}
        id={inputName}
        value={value}
        min={min}
        placeholder={placeholder}
        autoComplete={autocomplete}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        className={inputClasses}
      />
    </Wrapper>
  );
};

Input.propTypes = {
  labelClasses: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  inputClasses: PropTypes.string,
  type: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  errorText: PropTypes.string,
  errorBorderColor: PropTypes.string,
  defaultBorderColor: PropTypes.string,
  placeholder: PropTypes.string,
  asterisk: PropTypes.bool,
  min: PropTypes.number,
  autocomplete: PropTypes.oneOf(['on', 'off']),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  labelClasses: '',
  inputClasses: '',
  type: 'text',
  errorText: '',
  placeholder: '',
  asterisk: false,
  errorBorderColor: 'tomato',
  defaultBorderColor: '#a0aec0',
  min: 0,
  autocomplete: 'off',
  onChange: () => {
    return null;
  },
  onFocus: () => {
    return null;
  },
  onBlur: () => {
    return null;
  }
};
