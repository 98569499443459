/*  eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
`;

const Label = styled.label`
  position: relative;
  padding-left: 2.5rem;
  cursor: pointer;
  user-select: none;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  :checked ~ .checkmark {
    background: #2c5282;
  }
  :checked ~ .checkmark:after {
    display: block;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border: ${props => (props.isError ? '1px red solid' : '1px #a0aec0 solid')};
  background: white;
  :hover {
    background: #bee3f8;
  }
  ::after {
    content: '';
    position: absolute;
    display: none;
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const AsteriskStyle = styled.span`
  margin-left: 0.25rem;
  em {
    font-size: 0.8em;
    color: tomato;
    font-weight: 600;
  }
`;

const ErrorStyle = styled.span`
  color: red;
`;

export const Checkbox = ({
  labelNode,
  inputName,
  value,
  onChange,
  onFocus,
  errorText,
  asterisk,
  asteriskColor,
  asteriskSize,
  errorColor,
}) => {
  //

  return (
    <Wrapper>
      <Label htmlFor={inputName}>
        {errorText ? <ErrorStyle>{errorText}</ErrorStyle> : labelNode}
        <Input
          type="checkbox"
          name={inputName}
          id={inputName}
          value={value}
          errorText={errorText}
          onChange={onChange}
          onFocus={onFocus}
        />
        <Checkmark className="checkmark" isError={errorText} />
      </Label>
      <AsteriskStyle>
        {asterisk && !value ? (
          <>
            <em>[Required]</em>
          </>
        ) : (
          ''
        )}
      </AsteriskStyle>
    </Wrapper>
  );
};

Checkbox.propTypes = {
  asterisk: PropTypes.bool,
  asteriskSize: PropTypes.string,
  asteriskColor: PropTypes.string,
  errorColor: PropTypes.string,
  labelNode: PropTypes.node.isRequired,
  inputName: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  errorText: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
};

Checkbox.defaultProps = {
  asterisk: false,
  asteriskColor: '#000000',
  asteriskSize: '10px',
  errorColor: 'red',
  errorText: '',
  onChange: () => {
    return null;
  },
  onFocus: () => {
    return null;
  },
};
