/* eslint-disable react/no-danger */
/* eslint-disable import/prefer-default-export */

import React from 'react';
import PropTypes from 'prop-types';

export const JsonLd = ({ schema }) => {
  //
  return (
    <>
      <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
    </>
  );
};

JsonLd.propTypes = {
  schema: PropTypes.array.isRequired,
};
